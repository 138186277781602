export const stateMachineStates = {
  salesOrder: [
    {
      label: "states.salesOrder.exportStarted",
      value: "exportStarted"
    },
    {
      label: "states.salesOrder.exported",
      value: "exported"
    },
    {
      label: "states.salesOrder.importStarted",
      value: "importStarted"
    },
    {
      label: "states.salesOrder.imported",
      value: "imported"
    },
    {
      label: "states.salesOrder.error",
      value: "salesOrderError"
    }
  ],
  tracking: [
    {
      label: "states.stateExportStarted",
      value: "stateExportStarted"
    },
    {
      label: "states.statePartiallyExported",
      value: "statePartiallyExported"
    },
    {
      label: "states.stateExported",
      value: "stateExported"
    },
    {
      label: "states.stateImportStarted",
      value: "stateImportStarted"
    },
    {
      label: "states.statePartiallyImported",
      value: "statePartiallyImported"
    },
    {
      label: "states.cancelled",
      value: "cancelled"
    },
    {
      label: "states.stateImported",
      value: "stateImported"
    },
    {
      label: "states.noStateUpdate",
      value: "imported"
    },
    {
      label: "states.salesOrder.stateError",
      value: "stateError"
    }
  ],
  receipt: [
    {
      label: "states.receipt.exportStarted",
      value: "exportStarted"
    },
    {
      label: "states.receipt.exported",
      value: "exported"
    },
    {
      label: "states.receipt.importStarted",
      value: "importStarted"
    },
    {
      label: "states.receipt.imported",
      value: "imported"
    },
    {
      label: "states.receipt.error",
      value: "error"
    }
  ],
  paymentTransactions: [
    {
      label: "states.paymentTransactions.exportStarted",
      value: "exportStarted"
    },
    {
      label: "states.paymentTransactions.exported",
      value: "exported"
    },
    {
      label: "states.paymentTransactions.importStarted",
      value: "importStarted"
    },
    {
      label: "states.paymentTransactions.imported",
      value: "imported"
    },
    {
      label: "states.paymentTransactions.error",
      value: "error"
    }
  ],
  migration: {
    export: [
      {
        label: "states.product.exportStarted",
        value: "exportStarted"
      },
      {
        label: "states.product.exported",
        value: "exported"
      }
    ],
    import: [
      {
        label: "states.product.importStarted",
        value: "importStarted"
      },
      {
        label: "states.product.imported",
        value: "imported"
      }
    ]
  }
};
